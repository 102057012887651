export const safeJsonParse = <T = any>(str: string): T | null => {
  try {
    return JSON.parse(str)
  } catch (error) {
    return null
  }
}

interface LocalStorageOptions {
  raw?: boolean
}

export const getLocalStorage = <T = any>(key: string, options?: LocalStorageOptions): T | null => {
  if (typeof localStorage === 'undefined') return null

  const value = localStorage.getItem(key)
  if (!value) return null

  if (options?.raw) return value as any

  return safeJsonParse<T>(value)
}

export const setLocalStorage = <T = any>(key: string, value: T, options?: LocalStorageOptions): void => {
  if (typeof localStorage === 'undefined') return

  const realValue = options?.raw ? String(value) : JSON.stringify(value)

  localStorage.setItem(key, realValue)
}

export const removeLocalStorage = (key: string) => {
  if (typeof localStorage === 'undefined') return
  localStorage.removeItem(key)
}

export const getSessionStorage = <T = any>(key: string, options?: LocalStorageOptions): T | null => {
  if (typeof sessionStorage === 'undefined') return null

  const value = sessionStorage.getItem(key)
  if (!value) return null

  if (options?.raw) return value as any

  return safeJsonParse<T>(value)
}

export const setSessionStorage = <T = any>(key: string, value: T, options?: LocalStorageOptions): void => {
  if (typeof sessionStorage === 'undefined') return

  const realValue = options?.raw ? String(value) : JSON.stringify(value)

  sessionStorage.setItem(key, realValue)
}

export const removeSessionStorage = (key: string) => {
  if (typeof sessionStorage === 'undefined') return
  sessionStorage.removeItem(key)
}

export const isLocalDev =
  typeof location !== 'undefined' && location.hostname === 'localhost' && process.env.NEXT_PUBLIC_API_ENV !== 'pro'

export const TEST_DOMAINS = ['test.haiper.ai', 'testapp.haiper.ai', 'testapp1.haiper.ai', 'visionbrew.haiper.ai']
export const isTest =
  typeof location !== 'undefined' &&
  (TEST_DOMAINS.includes(location.hostname) || /^testapp\d+\.haiper\.ai$/.test(location.hostname))

export const isStage = typeof location !== 'undefined' && location.hostname === 'testappstage.haiper.ai'

export const isProduction = !isLocalDev && !isTest
